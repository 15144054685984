import restApiClient from "./client";
import { generateApiEndpoint } from "utils/api.utils";
import { ApiResponse, PaginatedApiResponse } from "../types/api.types";
import { Reimbursement, ReimbursementOverview, UpdateReimbursementPayload } from "types/reimbursement.types";

const ServiceName = "charging-reimbursements";

/**
 * GET: '/admin/charging-reimbursements'
 */
export async function getReimbursementListAsync(query?: string) {
  const endpoint = generateApiEndpoint(ServiceName);
  return (await restApiClient.get<PaginatedApiResponse<Reimbursement>>(endpoint + query)).data;
}


/**
 * GET: '/api/admin/organization/dashboard/monthlyStats'
 */
export async function getMonthlyReimbursementStatisticsAsync(): Promise<ApiResponse<any[]>> {
  const endpoint = generateApiEndpoint(`organization/dashboard/reimbursement/monthly-stats`);
  const response = await restApiClient.get(endpoint);
  return response.data;
}


/**
 * GET: '/api/admin/organization/dashboard/monthlyStats'
 */
export async function getMonthlyReimbursementOverviewAsync(): Promise<ApiResponse<ReimbursementOverview>> {
  const endpoint = generateApiEndpoint(`organization/dashboard/reimbursement/overview`);
  const response = await restApiClient.get(endpoint);
  return response.data;
}


/**
 * GET: '/admin/charging-reimbursements'
 */
export async function updateReimbursementAsync(id: string, payload: UpdateReimbursementPayload) {
  const endpoint = generateApiEndpoint(`${ServiceName}/${id}`);
  await restApiClient.patch<Promise<void>>(endpoint, payload);
}