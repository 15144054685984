/*
* Components
* */
import GeotabLogo from "assets/images/vehicle/GeotabLogo.png";
import SamsaraLogo from "assets/images/vehicle/SamsaraLogo.png";
import SmartcarLogo from "assets/images/vehicle/SmartcarLogo.png";
import CarIconWithCircleBackground from "components/icon/icons/CarIconWithCircleBackground";
import ChargerLighteningIcon from "components/icon/icons/ChargerLighteningIcon";
import TeslaIconWithCircleBackground from "components/icon/icons/TeslaIconWithCircleBackground";
import VehicleStatusAsleepIcon from "components/icon/icons/VehicleStatusAsleepIcon";
import VehicleStatusDriveIcon from "components/icon/icons/VehicleStatusDriveIcon";
import VehicleStatusParkingIcon from "components/icon/icons/VehicleStatusParkingIcon";
import VehicleStatusServiceIcon from "components/icon/icons/VehicleStatusServiceIcon";
import InboxDangerIcon from "../components/icon/icons/InboxDangerIcon";
/*
* Types
* */
import {
  AddVehicleFlowType,
  TeslaCommand,
  TeslaDataAccessorHook,
  TeslaKeyStatusDataAccessorHook,
  TeslaVehicleStatus,
  Vehicle,
  VehicleChargingSummary,
  VehicleConnectionState,
  VehicleDetailsRouteType,
  VehicleLocation,
  VehicleTelemetry
} from "../types/vehicle.types";
import { getFormattedNumberForEnergy } from "./charging.utils";
import { getDriverFullName } from "./driver.utils";

/*
* Utils
* */
import { isDevelopmentMode } from "./env.utils";
import { formatMilesByKmForEnUs, getFormattedNumberForUsdByCent } from "./format.utils";

export const vehicleDetailsRoutes = {
  overview: {
    ix: 0,
    path: "overview",
    label: "Overview",
    type: VehicleDetailsRouteType.DEFAULT,
    isTeslaRelated: false
  },
  about: {
    ix: 1,
    path: "about",
    label: "About",
    type: VehicleDetailsRouteType.DEFAULT,
    isTeslaRelated: false
  },
  charging: {
    ix: 2,
    path: "charging",
    label: "Charging",
    type: VehicleDetailsRouteType.DEFAULT,
    isTeslaRelated: false
  },
  assignments: {
    ix: 3,
    path: "assignments",
    label: "Assignments",
    type: VehicleDetailsRouteType.DEFAULT,
    isTeslaRelated: false
  },
  controls: {
    ix: 4,
    path: "controls",
    label: "Controls",
    type: VehicleDetailsRouteType.TESLA_CONTROLS,
    isTeslaRelated: true
  },
  alerts: {
    ix: 5,
    path: "alerts",
    label: "Alerts",
    type: VehicleDetailsRouteType.TESLA_ALERTS,
    isTeslaRelated: true
  }
};


export function getVehicleAllLocationInsightDetails(vehicleChargingSummary: VehicleChargingSummary | undefined) {
  const total = vehicleChargingSummary?.total;
  const totalSessionSize = total?.sessionSize?.count ?? 0;
  const publicSessions = vehicleChargingSummary?.public;
  const homeSessions = vehicleChargingSummary?.home;

  const publicSessionSize = publicSessions?.sessionSize?.count ?? 0;
  const homeSessionSize = homeSessions?.sessionSize?.count ?? 0;

  const formattedTotalCost = getFormattedNumberForUsdByCent(publicSessions?.cost?.count);
  const isTotalCostOverflow = formattedTotalCost?.length > 6;

  const formattedTotalEnergy = getFormattedNumberForEnergy(total?.energy?.count);
  const isTotalEnergyOverflow = formattedTotalEnergy?.length > 6;

  return {
    isTotalEnergyOverflow,
    isTotalCostOverflow,
    formattedTotalCost,
    formattedTotalEnergy,
    homeSessionSize,
    publicSessionSize,
    totalSessionSize,
    publicSessions,
    homeSessions
  };
}

export function getUniqueVehicles(vehicles) {
  return Array.from(new Map(vehicles.map((v) => [v.id, v])).values());
}

export function getVehicleSummaryName(vehicle) {
  if (!vehicle) {
    return "";
  }

  return `${vehicle.name}-${vehicle.carBrand}-${vehicle.carModel}-${vehicle.vin}`;
}

export function mapVehiclesForSelector(vehicles: Vehicle[]) {
  return vehicles.map((av) => {
    return { label: getVehicleSummaryName(av), value: av.id };
  });
}

export function mapSmartcarVehiclesForSelector(vehicles: VehicleLocation[]) {
  return vehicles.map((av) => {
    return { label: av.name, value: av };
  });
}

export function formatBatteryRemainingPercent(remainingBattery?: string | number) {
  if (!remainingBattery) {
    return 0;
  }

  const remainingBatteryNumber = Number(remainingBattery);

  return (remainingBatteryNumber).toFixed(0);
}

export const getVehicleModelYear = (vehicle?: Vehicle) => {
  const carYearDisplay = vehicle?.carYear ? " - " + vehicle?.carYear : "";
  return vehicle?.carModel + carYearDisplay;
};

export const getVehicleTelemetryDetails = (telemetry?: VehicleTelemetry) => {
  const battery = telemetry?.battery;
  const batteryRange = battery?.data?.range?.toString();
  const batteryPercentRemaining = battery?.data?.percentage?.toFixed(0)?.toString();
  const distance = telemetry?.odometer?.data?.odometer?.toString();

  return {
    odometer: telemetry?.odometer,
    battery,
    batteryPercentRemaining,
    batteryPercentRemainingDisplayText: batteryPercentRemaining ? `${batteryPercentRemaining}%` : "-",
    batteryRange,
    batteryRangeDisplayText: batteryRange ? formatMilesByKmForEnUs(batteryRange) + " mi." : "-",
    distance,
    odometerDisplayText: distance ? formatMilesByKmForEnUs(distance) + " mi." : "-"
  };
};

export const getVehicleConnectionStatus = (vehicle?: Vehicle) => {
  const smartcarConnectionState = vehicle?.smartcarConnectionState;
  const teslaConnectionState = vehicle?.teslaConnectionState;
  const geotabConnectionState = vehicle?.geotabConnectionState;
  const samsaraConnectionState = vehicle?.samsaraConnectionState;

  const isManualAddedCar =
    smartcarConnectionState === VehicleConnectionState.NOT_CONNECTED &&
    teslaConnectionState === VehicleConnectionState.NOT_CONNECTED &&
    geotabConnectionState === VehicleConnectionState.NOT_CONNECTED &&
    samsaraConnectionState === VehicleConnectionState.NOT_CONNECTED;

  const isTeslaConnected = teslaConnectionState === VehicleConnectionState.CONNECTED;
  const isSmartcarConnected = smartcarConnectionState === VehicleConnectionState.CONNECTED;
  const isGeotabConnected = geotabConnectionState === VehicleConnectionState.CONNECTED;
  const isSamsaraConnected = samsaraConnectionState === VehicleConnectionState.CONNECTED;

  const isSamsaraDisconnected = samsaraConnectionState === VehicleConnectionState.DISCONNECTED;
  const isGeotabDisconnected = geotabConnectionState === VehicleConnectionState.DISCONNECTED;
  const isSmartCarDisconnected = smartcarConnectionState === VehicleConnectionState.DISCONNECTED;

  let connectionStatus = vehicle ? VehicleConnectionState.CONNECTED : VehicleConnectionState.NOT_FOUND;

  if (isManualAddedCar) {
    connectionStatus = VehicleConnectionState.NOT_CONNECTED;
  } else if ((isSmartCarDisconnected || isGeotabDisconnected || isSamsaraDisconnected) && !isTeslaConnected) {
    connectionStatus = VehicleConnectionState.DISCONNECTED;
  }

  return {
    connectionStatus,
    isSmartCarDisconnected,
    isManualAddedCar,
    isTeslaConnected,
    isSmartcarConnected,
    isGeotabConnected,
    isSamsaraConnected
  };
};

export const getSmartCarPermissionScopes = () => {
  const scopes = [
    /**** Common Endpoints ****/
    "required:read_vehicle_info",
    "required:read_vin",
    "required:read_odometer",
    "required:read_location",
    "read_tires",
    "control_navigation",
    "control_security",
    /*** Common endpoints ***/

    /**** EV Specific Endpoints ****/
    "required:read_battery",
    "required:read_charge",
    "control_charge",
    /**** EV Specific Endpoints ****/

    /**** Ford & Lincoln Specific Endpoints ****/
    "read_charge_locations",
    /**** Ford & Lincoln Specific Endpoints ****/

    /**** Tesla Specific Endpoints ****/
    "read_extended_vehicle_info",
    "read_charge_records",
    "read_thermometer",
    "read_compass",
    "read_speedometer",
    "read_climate",
    "control_climate"
    /**** Tesla Specific Endpoints ****/
  ];

  if (!isDevelopmentMode()) {
    scopes.push("read_charge_events");
  }

  return scopes;
};

export const getTeslaPermissionScopes = () => {
  return [
    "openid",
    "offline_access",
    "user_data",
    "vehicle_device_data",
    "vehicle_cmds",
    "vehicle_charging_cmds",
    "energy_device_data",
    "energy_cmds"
  ];
};

export const getVehicleSummariesAsObjectForCsv = (vehicle?: Vehicle) => {
  return {
    vehicleName: vehicle?.name,
    vehicleMake: vehicle?.carBrand,
    vehicleModel: vehicle?.carModel,
    vehicleVin: vehicle?.vin
  };
};

export const getVehicleConnections = () => {
  return [VehicleConnectionState.CONNECTED, VehicleConnectionState.NOT_CONNECTED];
};

export const getVehicleConnectionOption = (connectionStatus: VehicleConnectionState) => {
  switch (connectionStatus) {
    case VehicleConnectionState.CONNECTED:
      return {
        label: "Connected",
        description: "Vehicles currently connected to the system",
        value: VehicleConnectionState.CONNECTED,
        textClass: "text-green-200"
      };
    case VehicleConnectionState.DISCONNECTED:
      return {
        label: "Disconnected",
        description: "Vehicles that were added via Smartcar but are now disconnected",
        value: VehicleConnectionState.CONNECTED,
        textClass: "text-cherry-100"
      };
    case VehicleConnectionState.NOT_CONNECTED:
      return {
        label: "Not connected",
        description: "Vehicles manually added by using their VIN",
        value: VehicleConnectionState.CONNECTED,
        textClass: "text-purple-200"
      };

    default:
      return {
        label: "Not connected",
        description: "Vehicles manually added by using their VIN",
        value: VehicleConnectionState.CONNECTED,
        textClass: "text-purple-200"
      };
  }
};


export const getTeslaVehicleStatusContent = (status?: TeslaVehicleStatus) => {
  switch (status) {
    case TeslaVehicleStatus.IN_SERVICE:
      return { icon: <VehicleStatusServiceIcon />, label: "Service" };

    case TeslaVehicleStatus.IN_MOTION:
      return { icon: <VehicleStatusDriveIcon />, label: "Driving" };

    case TeslaVehicleStatus.PARKED:
      return { icon: <VehicleStatusParkingIcon />, label: "Parked" };

    case TeslaVehicleStatus.CHARGING:
      return { icon: <ChargerLighteningIcon fill={"#23d206"} />, label: "Charging", textClass: "text-green-200" };

    case TeslaVehicleStatus.ASLEEP:
      return { icon: <VehicleStatusAsleepIcon />, label: "Sleep mode" };
    default:
      return { icon: <InboxDangerIcon />, label: "Unknown" };
  }
};


export const getAddVehicleFlowStarterContentInfo = (flowType: AddVehicleFlowType) => {
  switch (flowType) {
    case AddVehicleFlowType.SMARTCAR:
      return {
        icon: (
          <div className="w-min-65px">
            <img width={65} height={65} src={SmartcarLogo} alt="Smart car logo" />
          </div>
        ),
        miniTitle: "",
        title: "Connect EVs",
        description:
          "Use the vehicle connection option to automatically fetch detailed data and insights on your vehicle through telematics.",
        footer: <></>,
        route: "add-vehicle/smartcar"
      };

    case AddVehicleFlowType.TESLA:
      return {
        icon: <TeslaIconWithCircleBackground />,
        title: "Connect Teslas",
        description:
          "Use the Tesla connection to automatically fetch detailed data and control your vehicle with enhanced telematics insights.",
        footer: <></>,
        route: "add-vehicle/tesla"
      };


    case AddVehicleFlowType.GEOTAB:
      return {
        icon: (
          <div className="w-min-65px">
            <img width={65} height={65} src={GeotabLogo} alt="Geotab logo" />
          </div>
        ),
        title: "Connect Geotab",
        description:
          "Authorize Bluedot access in your Geotab account. This allows us to securely import your necessary vehicle data.",
        footer: <></>,
        route: "add-vehicle/geotab"
      };

    case AddVehicleFlowType.SAMSARA:
      return {
        icon: (
          <div className="w-min-65px">
            <img width={65} height={65} src={SamsaraLogo} alt="Samsara logo" />
          </div>
        ),
        title: "Connect Samsara",
        description: "Import your Samsara fleet data for complete vehicle monitoring and insights.",
        route: "add-vehicle"
      };

    default:
      return {
        icon: <CarIconWithCircleBackground />,
        miniTitle: "",
        title: "Add Manually ",
        description:
          "Use the manual entry option to add vehicles using the VIN. Please note that this option does not provide live data synchronization.",
        footer: <></>,
        route: "add-vehicle/manual"
      };
  }
};


export const getVehicleCommandAlertSuccessMessage = (command: TeslaCommand) => {
  switch (command) {
    case TeslaCommand.HONK_HORN:
      return "Honk horn command is successfully sent";
    case TeslaCommand.FLASH_LIGHTS:
      return "Flash light command is successfully sent";
    case TeslaCommand.ACTUATE_REAR_TRUNK:
      return "Trunk command is successfully sent";
    case TeslaCommand.ACTUATE_FRONT_TRUNK:
      return "Frunk command is successfully sent";
    default:
      return "Command is successfully sent";
  }
};


export const getTeslaControlInfoDetails = (teslaHook: TeslaDataAccessorHook) => {

  const vehicleState = teslaHook.teslaInfo;
  const remoteStart = vehicleState?.remoteStart;

  const isRemoteStartVisible = remoteStart?.supported && remoteStart?.enabled;

  return { isRemoteStartVisible };

};


export const getTeslaKeyControlInfoDetails = (teslaKeyStatusHook: TeslaKeyStatusDataAccessorHook) => {
  const { keyStatus, isLoading } = teslaKeyStatusHook;
  const isKeySupported = keyStatus?.key.supported;
  const isOldProtocol = !isKeySupported;
  const isKeyPaired = keyStatus?.key.paired;
  const isNewProtocolAndKeyNotPaired = !isOldProtocol && !isKeyPaired && !teslaKeyStatusHook.isLoading;
  const isControlsSupported = isOldProtocol || isKeyPaired;
  const isControlsVisibleByKeyStatus = isControlsSupported && !teslaKeyStatusHook.isLoading;


  return {
    isKeySupported,
    isOldProtocol,
    isKeyPaired,
    isNewProtocolAndKeyNotPaired,
    isControlsSupported,
    isControlsVisibleByKeyStatus
  };
};


export const getVehicleRowForCsv = (row: Vehicle) => {
  // We will add vehicle details as separated columns.
  const {
    users,
    smartcarConnectionState,
    teslaConnectionState,
    version,
    imagesBaseUrl,
    deletedAt,
    id,
    isDeleted,
    organizationId,
    carBrand,
    carModel,
    carYear,
    name,
    ...otherDatas
  } = row;

  return {
    name,
    brand: carBrand,
    model: carModel,
    year: carYear,
    driver: users.length > 0 ? getDriverFullName(users[0]) : "-",
    ...otherDatas
  };
};

