import { ChevronRight } from "@mui/icons-material";
import LoadingSkeletonChargingListTable from "components/loading/LoadingSkeletonChargingListTable";

import PaginatedTable from "components/table/PaginatedTable";
import { BlackBodyText100, BlackBodyText200 } from "components/text/Text";
import { useInboxMessagesAsync } from "hooks/data/inbox-data-accessor.hooks";
import { useAppDispatch } from "hooks/redux.hooks";
import parse from "html-react-parser";
import React, { useMemo } from "react";
import { setSelectedInboxMessage } from "redux/slices/inboxSlice";
import { InboxMessage, InboxMessageType } from "types/inbox.types";
import { TableColumn } from "types/table.types";
import { getLocalFormattedDateAsDateShort } from "utils/date.utils";
import InboxMessageDrawer from "./InboxMessageDrawer";
import InboxMessagesTab from "./InboxMessagesTab";
import InboxMessagesTablePlaceholder from "./InboxMessagesTablePlaceholder";
import {
  useBindAllVisibleInboxMessageLinks,
  useInboxBulkActionHooks,
  useInboxQueryHooks
} from "../../hooks/inbox.hooks";
import ToggleCheckbox from "../input/checkbox/ToggleCheckbox";
import TableSelectableHeader from "../table/TableSelectableHeader";
import Row from "../custom/Row";
import FilterSearchInput from "../filter/FilterSearchInput";
import InboxMessageIcon from "./InboxMessageIcon";

type Props = {};
export type InboxMessageTableColumn = TableColumn<InboxMessage>;

function InboxMessages({}: Props) {
  const dispatch = useAppDispatch();
  const inboxMessagesDataAccessorHooks = useInboxMessagesAsync();
  const { isLoading, messages, meta } = inboxMessagesDataAccessorHooks;


  useBindAllVisibleInboxMessageLinks(isLoading);
  const {
    activeTab,
    handleSetProvidersOnQuery,
    isActiveTabArchived
  } = useInboxQueryHooks();

  const inboxMessagesBulkActionHooks = useInboxBulkActionHooks(inboxMessagesDataAccessorHooks);
  const {
    handleSelectRow,
    isRowChecked
  } = inboxMessagesBulkActionHooks;


  const columns = useMemo(
    () => {

      const baseColumns = [
        {
          style: { padding: 0, paddingLeft: 10 },
          width: "40px",
          name: "readStatus",
          cell: (row) =>
            <div className="w-100 center">
              <ToggleCheckbox isChecked={isRowChecked(row.id)} onToggle={() => handleSelectRow(row)} />
            </div>
        },
        {
          style: { padding: 0, paddingLeft: isActiveTabArchived ? 10 : 0 },
          width: "30px",
          name: "readStatus",
          cell: (row) =>
            !row?.read && (
              <div className="w-100 center">
                <div className="alert-blue-dot" />
              </div>
            )
        },
        {
          style: { padding: 0, paddingLeft: 5 },
          width: "45px",
          name: "alertType",
          selector: (row: InboxMessage) => (
            <div className="center pointer-events-none">
              <InboxMessageIcon type={row.data.__typename as InboxMessageType} level={row?.level} />
            </div>
          )
        },
        {
          style: { padding: 0 },
          name: "title",
          grow: 1,
          width: "62vw",
          wrap: false,
          cell: (row) => (
            <div className="d-flex flex-nowrap pointer-events-none w-max-100 inbox-message-row align-items-center">
              <BlackBodyText100 className="font-weight-500 text-nowrap w-max-32">
                {parse(row.title.substring(0, 250))}
              </BlackBodyText100>
              <BlackBodyText200 className="pl-1 w-min-24px text-no-truncate">-</BlackBodyText200>
              <BlackBodyText200 className="text-nowrap">{parse(row.body.substring(0, 250))}</BlackBodyText200>
            </div>
          )
        },

        {
          ignoreRowClick: false,
          grow: 1,
          minWidth: "120px",
          style: { padding: 0 },
          name: "date",
          cell: (row) => (
            <div className="w-100 center pointer-events-none">
              {getLocalFormattedDateAsDateShort(row?.createdAt)}
            </div>
          )
        },
        {
          style: { padding: 0 },
          width: "40px",
          name: "action",
          selector: (row) => (
            <div className="d-flex justify-content-end">
              <ChevronRight />
            </div>
          )
        }
      ];

      if (isActiveTabArchived) {
        baseColumns.shift();
      }

      return baseColumns;
    },
    [handleSelectRow, isActiveTabArchived, isRowChecked]
  ) as InboxMessageTableColumn[];

  const handleOpenDrawer = (message: InboxMessage) => dispatch(setSelectedInboxMessage(message));


  const areThereAnyMessage = messages.length > 0;

  return (
    <>
      <InboxMessagesTab activeTab={activeTab} onTabChange={handleSetProvidersOnQuery} />
      <Row className={isActiveTabArchived ? "my-2 mt-4" : `my-4`}>
        <FilterSearchInput placeholder="Search messages" />
      </Row>
      <div className={`${isLoading ? "mt-3" : ""}`}>
        {!isLoading && !isActiveTabArchived && areThereAnyMessage && <TableSelectableHeader
          inboxMessagesBulkActionHooks={inboxMessagesBulkActionHooks}
        />}
        <PaginatedTable
          conditionalRowStyles={
            isActiveTabArchived ? undefined : [
              {
                when: row => isRowChecked(row.id),
                style: {
                  backgroundColor: "#E5EAFF",
                  "&:hover": {
                    backgroundColor: "#E5EAFF"
                  }
                }
              }
            ]}
          noDataComponent={<InboxMessagesTablePlaceholder />}
          onRowClicked={handleOpenDrawer}
          paginationTotalRows={meta?.pagination.totalElements}
          className={`d-lg-block rounded-xxl w-max-100  ${(isActiveTabArchived || !areThereAnyMessage) ? "table-no-header" : "table-selectable"} `}
          columns={columns}
          data={messages}
          isLoading={isLoading}
          progressComponent={
            <div className="w-100 h-100 p-2 d-flex flex-column  rounded-xxl align-items-start">
              <LoadingSkeletonChargingListTable count={20} />
            </div>
          }
          noTableHead={true}
        />
      </div>
      <InboxMessageDrawer />
    </>
  );
}

export default InboxMessages;
