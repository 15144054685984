import React, { useCallback, useState } from "react";
import EditorModal from "../modal/EditorModal";
import { NeutralBodySmallText300, NeutralTitleSmallText100, NeutralTitleSmallTextBlack } from "../text/Text";
import HorizontalLine from "../line/HorizontalLine";
import { handleApiErrorResponse } from "../../utils";
import { useAlertManager } from "../../hooks/alert.hooks";
import { useForm } from "react-hook-form";
import MapWithRectangleBounds from "../map/MapWithRectangleBounds";
import { FormGroup } from "reactstrap";
import { useMapWithRectangleBoundsHooks } from "../../hooks/map.hooks";
import VioletInfoBadge from "../badge/VioletInfoBadge";
import {
  addDepotChargingLocationAsync,
  updateDepotChargingLocationAsync
} from "../../services/depot-charging-location.service";
import { getPolygonLocationByRectangleBoundsAndName } from "../../utils/polygon.utils";
import { OrganizationDepotChargingLocation } from "../../types/depot-charging-location.types";


type FormState = {
  name: string
}

type Props = {
  depotChargingLocationToUpdate?: OrganizationDepotChargingLocation | null
  setDepotChargingLocationToUpdate?: (location: OrganizationDepotChargingLocation | null) => void
  isLoading?: boolean
  isOpen: boolean
  setIsOpen: (p: boolean) => void,
  refetch: () => void,
}

const DepotChargingLocationMapEditor = ({
                                          depotChargingLocationToUpdate,
                                          setDepotChargingLocationToUpdate,
                                          isLoading,
                                          isOpen,
                                          setIsOpen,
                                          refetch
                                        }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const mapWithRectangleBoundsHooks = useMapWithRectangleBoundsHooks(undefined, depotChargingLocationToUpdate?.polygon, isLoading);
  const { boundsToSubmit } = mapWithRectangleBoundsHooks;


  const { handleSubmit, register } = useForm<FormState>({
    mode: "all"
  });

  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();

  const handleSubmitLocation = useCallback(async (data: FormState) => {
    try {
      if (!boundsToSubmit) {
        handleOpenErrorAlert("Missing location bounds");
        return;
      }
      const polygonLocationPayload = { location: getPolygonLocationByRectangleBoundsAndName(boundsToSubmit, data.name) };
      setIsSubmitting(true);
      if (depotChargingLocationToUpdate && setDepotChargingLocationToUpdate) {
        await updateDepotChargingLocationAsync(depotChargingLocationToUpdate.id, polygonLocationPayload);
        setDepotChargingLocationToUpdate(null);
      } else {
        await addDepotChargingLocationAsync(polygonLocationPayload);
      }

      refetch();
      handleOpenSuccessAlert("Depot location saved successfully.");
      setIsOpen(false);
    } catch (err) {
      handleOpenErrorAlert(handleApiErrorResponse(err));
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  }, [boundsToSubmit, depotChargingLocationToUpdate, handleOpenErrorAlert, handleOpenSuccessAlert, refetch, setDepotChargingLocationToUpdate, setIsOpen]);

  return (
    <EditorModal
      modalSize="wide-sm"
      setIsModalOpen={setIsOpen}
      isModalOpen={isOpen}
      onSubmit={handleSubmit(handleSubmitLocation)}
      isLoading={isSubmitting}
    >
      <div className={"h-max-80vh overflow-auto"}>
        <NeutralTitleSmallText100>Depot Name:</NeutralTitleSmallText100>
        <NeutralBodySmallText300 className={"mb-2"}>Enter a unique identifier for this charging
          location.</NeutralBodySmallText300>
        <FormGroup>
          <input
            required={true}
            className="form-control w-300px"
            type={"text"}
            name={"name"}
            defaultValue={depotChargingLocationToUpdate?.name}
            placeholder={"Enter name"}
            ref={
              register({
                required: `Name is required.`
              })
            }
          />
        </FormGroup>

        <HorizontalLine />
        <MapWithRectangleBounds
          {...mapWithRectangleBoundsHooks}
          mapId={"depot-charging-map-container"}
          titleOverrides={<>
            <NeutralTitleSmallTextBlack className={"mb-1"}>Depot Address:</NeutralTitleSmallTextBlack>
            <VioletInfoBadge
              className={"mb-3"}
              description={"Enter an address and use the polygon tool on the map to define your depot charging area."}
            />
          </>}

        />
      </div>
    </EditorModal>
  );
};

export default DepotChargingLocationMapEditor;