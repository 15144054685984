import LoadingSkeleton from "components/loading/LoadingSkeleton";
import { TeslaDataAccessorHook, TeslaKeyStatusDataAccessorHook } from "types/vehicle.types";
import VehicleDetailsPinToDriveEditor from "./VehicleDetailsPinToDriveEditor";
import VehicleDetailsSystemStatusEditor from "./VehicleDetailsSystemStatusEditor";
import { BlackOverlineText200 } from "../../text/Text";
import Col from "../../custom/Col";
import BlackInfoIcon from "components/icon/icons/BlackInfoIcon";
import VehicleDetailsAntiTheftModeEditor from "./VehicleDetailsAntiTheftModeEditor";
import React from "react";
import { getTeslaKeyControlInfoDetails } from "../../../utils/vehicle.utils";

type Props = {
  teslaHook: TeslaDataAccessorHook;
  teslaKeyStatusHook: TeslaKeyStatusDataAccessorHook
};

function VehicleDetailsDriveEditors({ teslaHook, teslaKeyStatusHook }: Props) {
  const { isControlsVisibleByKeyStatus } = getTeslaKeyControlInfoDetails(teslaKeyStatusHook);

  const { keyStatus, isLoading } = teslaKeyStatusHook;


  return (
    <div className="">
      {teslaHook.isTeslaDrivePinLoading || isLoading ? (
        <LoadingSkeleton count={2} height={30} />
      ) : (
        <>
          {keyStatus?.key.supported ?
            (
              <>
                <VehicleDetailsSystemStatusEditor
                  teslaHook={teslaHook}
                />
                <VehicleDetailsPinToDriveEditor
                  teslaHook={teslaHook}
                />
              </>
            )
            :
            (
              <Col>
                <BlackInfoIcon />
                <BlackOverlineText200 className="mt-1">
                  Please note that pre-2021 Tesla Model X and Model S vehicles do not support the virtual key feature.
                  As
                  a result, certain options like PIN to Drive are unavailable for these models. For more details, visit
                  the manufacturer’s website.
                </BlackOverlineText200>
              </Col>
            )
          }


          {!teslaHook.isTeslaSleeping &&
            <>
              <div className="horizontal-border-line my-2 w-100" />
              <VehicleDetailsAntiTheftModeEditor teslaHook={teslaHook}
                                                 isControlsVisibleByKeyStatus={isControlsVisibleByKeyStatus} />
            </>

          }
        </>
      )}
    </div>
  );
}

export default VehicleDetailsDriveEditors;
